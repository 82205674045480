import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=28d7e4b5&scoped=true&"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=28d7e4b5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28d7e4b5",
  null
  
)

export default component.exports