<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">智慧工地</div>
        <div class="sub_ttl">
          基于墨器提供的硬件SDK和API，升级硬件能力，并提供软硬一体的场景化方案，实现算法与硬件的深度落地，配备预设应用，提供智能硬件集采，提供已有硬件对接服务，实现产业升级。
        </div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box4">
      <div class="title">上帝视角 实时掌控</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box4.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box4.slides"
            :key="idx"
            @click="box4.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
        <div class="pic_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box4.swiper = sw)"
            @slideChange="() => (box4.crtIndex = box4.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box4.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box4.crtIndex === i ? 'z-crt' : '']"
              @click="box4.swiper.slideTo(i)"
              v-for="(item, i) in box4.slides"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="title">规范管理 文明施工</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="pic_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box2.swiper = sw)"
            @slideChange="() => (box2.crtIndex = box2.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box2.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box2.crtIndex === i ? 'z-crt' : '']"
              @click="box2.swiper.slideTo(i)"
              v-for="(item, i) in box2.slides"
              :key="i"
            />
          </div>
        </div>
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box2.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box2.slides"
            :key="idx"
            @click="box2.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box7">
      <div class="title">安全防护 实时预警</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box7.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box7.slides"
            :key="idx"
            @click="box7.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
        <div class="pic_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box7.swiper = sw)"
            @slideChange="() => (box7.crtIndex = box7.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box7.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box7.crtIndex === i ? 'z-crt' : '']"
              @click="box7.swiper.slideTo(i)"
              v-for="(item, i) in box7.slides"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="title">智能硬件设备订购</div>
      <div class="main_box">
        <div class="case_wrap">
          <template v-for="(c, idx) in box3.list">
            <div
              class="case_item"
              @click.stop="showDeviceDetail(c.id)"
              v-if="idx < box3.max"
              :key="c.id"
            >
              <div
                class="cover"
                :style="{
                  backgroundImage: `url(${c.cover})`,
                }"
              ></div>
              <div class="info">
                <div class="ttl">{{ c.name }}</div>
                <div class="desc">{{ c.desc }}</div>
                <div class="more" flex="cross:center">
                  了解详情<img
                    class="i"
                    src="@/assets/img/ic_32_箭头@3x.png"
                    alt=">"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="more_btn"
        @click="box3.max = box3.list.length"
        v-if="box3.max < box3.list.length"
      >
        <img
          class="i"
          src="@/assets/img/展开@3x.jpg"
          alt="查看其他设备"
        />查看其他设备
      </div>
    </div>
    <div class="trial_bar">
      <div class="main_box" flex="main:justify cross:center">
        <div class="ttl">为您打造一套数智工程管理解决方案</div>
        <div class="btn" @click="showTrialModal">申请体验</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";
import devices from "@/data/devices";

const baseUrl = `${process.env.BASE_URL}public/img/smart_construction/pic`;

const box2 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "智能“AI摄像头”",
      desc: "针对作业区域的人员进行自动识别，监测人员的违章情况，系统发起报警信号。",
      pic: `${baseUrl}/2_1.png`,
    },
    {
      ttl: "环境监测系统",
      desc: "环境质量状况进行监视和测定，以确保环境污染状况和环境质量的高低。",
      pic: `${baseUrl}/2_2.png`,
    },
    {
      ttl: "智能喷淋系统",
      desc: "结合墨器星盘设置定时喷淋、高温自动喷淋、扬层监测喷淋系统。",
      pic: `${baseUrl}/2_3.png`,
    },
  ],
};

const box3 = {
  max: 4,
  list: [...devices],
};

const box4 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "地图模式",
      desc: "实时查看工地各硬件状态，把控施工安全。",
      pic: `${baseUrl}/模式_地图.png`,
    },
    {
      ttl: "图片模式",
      desc: "AI人脸识别+图片轮播模式，自由上传图片，自定义展示内容。",
      pic: `${baseUrl}/模式_图片.png`,
    },
    {
      ttl: "3D模式",
      desc: "上传BIM模型，项目落地效果一览无余，还原模型最终效果。",
      pic: `${baseUrl}/模式_3D.png`,
    },
    {
      ttl: "航拍模式",
      desc: "上传航拍视频或安装无人机实时监控，实施跟进施工进度。",
      pic: `${baseUrl}/模式_航拍.png`,
    },
  ],
};

const box7 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "塔吊监控系统",
      desc: "实时监控群塔作业，实现塔吊防碰撞，限制塔吊旋转角度，并进行分级报警。",
      pic: `${baseUrl}/3_1.png`,
    },
    {
      ttl: "升降机监测系统",
      desc: "实时监控预警报警、数据远传，平台集中监控与管理功能。",
      pic: `${baseUrl}/3_2.png`,
    },
    {
      ttl: "工地广播系统",
      desc: "预警广播系统，系统监测到预警信息后会立即广播进行工地通报。",
      pic: `${baseUrl}/3_3.png`,
    },
  ],
};

export default {
  components: {
    Footer,
  },
  data() {
    return {
      box2,
      box3,
      box4,
      box7,
    };
  },
  methods: {
    ...mapActions(["showTrialModal", "showDeviceDetail"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/banner 8.png") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 9.4rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
    width: 35rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box3 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .tabs {
    margin-top: 1.6rem;
    display: flex;
    justify-content: center;

    .item {
      width: 8rem;
      height: 2rem;
      line-height: 2rem;
      border: 1px solid #ddd;
      border-radius: 0.3rem;
      text-align: center;
      font-size: 0.9rem;
      color: #666;
      margin-right: 0.9rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.z-crt {
        background-color: #2574ff;
        border-color: #2574ff;
        color: #fff;
        cursor: auto;
      }
    }
  }

  .main_box {
    overflow: hidden;

    .case_wrap {
      overflow: hidden;
      padding-top: 3.3rem;
      width: $mainWidth + 3rem;
    }
  }

  .case_item {
    float: left;
    margin: 0 2.7rem 2.7rem 0;
    width: 15rem;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: translate3d(0, -0.15rem, 0);
    }

    .cover {
      width: 100%;
      height: 15rem;
      background-size: cover;
    }

    .info {
      margin-top: 0.8rem;
      padding: 0 0.5rem;
      overflow: hidden;
    }

    .ttl {
      font-size: 0.9rem;
      color: #1a1a1a;
      line-height: 1.35rem;
    }

    .desc {
      margin-top: 0.35rem;
      font-size: 0.7rem;
      color: #858b96;
      line-height: 1.1rem;
      max-height: 2.5rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .more {
      margin-top: 0.6rem;
      font-size: 0.7rem;
      font-weight: bold;
      color: #0078f0;
      line-height: 1rem;

      .i {
        display: block;
        margin-left: 0.2rem;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  .more_btn {
    margin: 0 auto;
    width: 9.5rem;
    height: 2rem;
    border: 1px solid #2574ff;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    font-weight: bold;
    color: #2574ff;
    cursor: pointer;

    .i {
      display: block;
      width: 0.9rem;
      height: 0.9rem;
      margin-right: 0.3rem;
    }
  }
}

.box2 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    padding-right: 2rem;
    box-sizing: border-box;
  }
}

.box4,
.box7 {
  overflow: hidden;
  background-color: #fff;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    padding-left: 2rem;
    box-sizing: border-box;
  }
}

.trial_bar {
  background-color: #f0f1f6;
  padding: 0 0 1.6rem;

  .main_box {
    background: url("~@/assets/bg/申请体验背景.png") no-repeat center center;
    background-size: cover;
    height: 7rem;
  }

  .ttl {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.5rem;
    letter-spacing: 0.15rem;
  }

  .btn {
    margin-right: 4rem;
    width: 7rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #0078f0;
    line-height: 2.4rem;
    cursor: pointer;

    &:hover {
      background-color: #dce8ff;
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}

.row_tabs {
  width: 28.5rem;

  .item {
    margin-bottom: 2rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.z-crt {
      .num {
        opacity: 1;
      }

      .ttl {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-top: 0;
      }
    }

    .num {
      width: 1.7rem;
      height: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      background-color: #0078f0;
      border-radius: 0.6rem;
      opacity: 0.2;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      margin-right: 0.8rem;
    }

    .ttl {
      font-size: 0.9rem;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 1.35rem;
      margin-top: 0.2rem;
    }

    .desc {
      margin-top: 0.4rem;
      font-size: 0.7rem;
      color: #9a9a9a;
      line-height: 1.1rem;
    }
  }
}

.pic_swp {
  width: 34rem;

  .swp {
    height: 19.15rem;

    .pic {
      display: block;
      width: 100%;
    }
  }

  .pagination {
    margin-top: 0.8rem;

    .dot {
      width: 0.6rem;
      height: 0.15rem;
      opacity: 0.4;
      background-color: #2574ff;
      border-radius: 0.1rem;
      cursor: pointer;
      transition: opacity 0.3s;
      margin-right: 0.4rem;

      &:last-child {
        margin-right: 0;
      }

      &.z-crt {
        opacity: 1;
      }
    }
  }
}
</style>
